<template>
  <div
    class="my-account"
    element-loading-background="rgba(255, 255, 255, 0.95)"
  >
    <div class="mya-item">
      <h2>1. Profile</h2>
      <div class="ma-wrap">
        <div class="ma-face">
          <img
            :src="
              user.profilePath == 'defaultPath'
                ? require('../../assets/images/img-face.png')
                : 'data:image/png;base64,' + avatar
            "
          />
          <!-- <lay-ripple>
            <el-button round size="small" @click="changeAvatar">
              Change<el-icon class="el-icon--right"><Upload /></el-icon>
            </el-button>
          </lay-ripple>
          <lay-layer
            title="Change Your Avatar"
            v-model="visible"
            :area="['400px', '250px']"
          >
            <div style="padding: 20px">
              <a-config-provider :locale="enUS">
                <a-upload
                  :custom-request="myUpload"
                  list-type="picture"
                  accept="image/*"
                  :limit="1"
                  draggable
                  success
                  :image-preview="true"
                  :show-remove-button="false"
                >
                </a-upload>
              </a-config-provider>

              <el-button
                @click="upload"
                type="primary"
                style="margin: 20px 140px"
                >Refresh</el-button
              >
            </div>
          </lay-layer> -->
        </div>
        <div class="ma-text">
          <div class="ma-item">
            <label>User Name : </label>
            <lay-input
              type="text"
              placeholder=""
              class="form-input"
              v-model="uname"
            />
            <div class="icon-edit">
              <el-tooltip effect="light" content="reset" placement="bottom">
                <el-icon @click="uname = user.uname" style="font-size: 23px"
                  ><RefreshLeft
                /></el-icon>
              </el-tooltip>
            </div>
          </div>
          <div class="ma-item">
            <label>Email : </label>
            <lay-input
              type="text"
              placeholder=""
              class="form-input"
              v-model="email"
            />
            <div class="icon-edit">
              <el-tooltip effect="light" content="reset" placement="bottom">
                <el-icon @click="email = user.email" style="font-size: 23px"
                  ><RefreshLeft
                /></el-icon>
              </el-tooltip>
            </div>
          </div>
          <div class="ma-item">
            <label>Change Password : </label>
            <lay-input
              :type="showPass"
              placeholder="6+ characters"
              class="form-input"
              v-model="newPass"
            >
              <template #suffix>
                <div v-show="filled" class="show-hide" @click="showHide">
                  <ShowIcon
                    v-if="isShow"
                    style="font-weight: 900; font-size: 15px; color: #090970"
                  />
                  <HideIcon
                    v-else
                    style="font-weight: 900; font-size: 15px; color: #090970"
                  />
                </div>
              </template>
            </lay-input>
            <div class="icon-edit">
              <el-tooltip
                effect="light"
                content="check valid"
                placement="bottom"
              >
                <font-awesome-icon
                  @click="check"
                  icon="fa-regular fa-pen-to-square"
                  style="font-size: 23px"
                />
              </el-tooltip>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mya-item">
      <h2>2. Cookie Preferences</h2>
      <div class="mya-wrap">
        <span class="myai-text"
          >We use cookies & similar technologies to improve and personalise your
          user experience. <a href="">Cookie policy</a></span
        >
        <a href="" class="myai-btna" style="pointer-events: none;">Edit</a>
      </div>
    </div>
    <div class="mya-item">
      <h2>3. Delete your data and account</h2>
      <div class="mya-wrap">
        <span class="myai-text">Delete your data and account</span>
        <a href="" class="myai-btna" style="pointer-events: none;">Delete</a>
      </div>
    </div>
    <div class="btn-box">
      <a href="javascript:" class="mya-btn" @click="save">Save</a>
      <div class="upgrade-downgrade-btns">
        <el-button type="danger" @click="modifyAccount">Modify My Account Level</el-button>
      </div>
    </div>

    <el-dialog v-model="modifyDialogVisible" title="Modify Account Level">
      <el-radio-group v-model="newLevel">
        <el-radio v-for="(l, k) in level" :key="k" :label="k">
          <el-tag :type="l.tag"> Lv.{{ k }} {{ l.name }} </el-tag>
        </el-radio>
      </el-radio-group>
      <template v-slot:footer>
        <el-button @click="modifyDialogVisible = false">Cancel</el-button>
        <el-button type="primary" @click="confirmModification">Confirm</el-button>
      </template>
    </el-dialog>

    <div class="ma-item">
      <label>Email Notifications : </label>
      <el-switch
        v-model="receiveEmail"
        active-color="#13ce66"
        inactive-color="#ff4949"
        @change="toggleEmailReceive"
      ></el-switch>
    </div>
    
  </div>
</template>

<script>
import { ShowIcon, HideIcon } from "@layui/icons-vue";
import { ref, watch } from "vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { post, get } from "../../api";
import { ElMessage, ElMessageBox } from "element-plus";
import { RefreshLeft } from "@element-plus/icons-vue";
import { layer } from "@layui/layui-vue";
import enUS from "@arco-design/web-vue/es/locale/lang/en-us";
import { useRoute } from "vue-router";

export default {
  name: "AccountSettings",
  computed: {
    RefreshLeft() {
      return RefreshLeft;
    },
  },
  components: {
    FontAwesomeIcon,
    ShowIcon,
    HideIcon,
  },
  setup() {
    const loading = ref(false);
    const route = useRoute();
    const uploadRef = ref();
    const uname = ref("");
    const email = ref("");
    const user = ref({});
    const visible = ref(false);
    const newPass = ref("");
    const showPass = ref("password");
    const isShow = ref(true);
    const filled = ref(false);
    const avatar = ref("");
    const modifyDialogVisible = ref(false);
    const receiveEmail = ref(false);
    const newLevel = ref(0);
    const level = [
      { name: "Regular", tag: "success" },
      { name: "Moderator", tag: "" },
      { name: "Admin", tag: "warning" },
      { name: "Super", tag: "danger" },
    ];

    const myUpload = (option) => {
      const { onProgress, onError, onSuccess, fileItem, name } = option;
      const formData = new FormData();
      formData.append("uploadFile", fileItem.file);
      formData.append("uid", route.query.uid);
      post("/file/upload", formData, {
        headers: {
          "Content-type": "multipart/form-data",
        },
        onUploadProgress: (event) => {
          let percent;
          if (event.total > 0) {
            percent = event.loaded / event.total;
          }
          onProgress(percent, event);
        },
      }).then((res) => {
        if (res.res != 1) {
          ElMessage({
            type: "warning",
            message: "Uploading Failed",
          });
          return onError(res.res);
        }
        ElMessage({
          type: "success",
          message: "Uploading successful",
        });
        location.reload()
        onSuccess(res.res);
      });
    };

    const upload = () => {
      location.reload();
    };

    function showHide() {
      let tag = !isShow.value;
      showPass.value = tag ? "text" : "password";
      isShow.value = tag;
    }

    watch(
      () => newPass.value,
      (to) => {
        filled.value = to !== "";
      }
    );

    function changeAvatar() {
      visible.value = !visible.value;
    }

    function check() {
      const regPassword = /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9]).{6,}/;
      if (regPassword.test(newPass.value)) {
        ElMessage({
          message: "Valid Password",
          type: "success",
        });
      } else {
        ElMessage({
          message:
            "Please input 6+ characters, MUST containing capital and lowercase letters, and number.",
          type: "warning",
        });
      }
    }

    function save() {
      const regPassword = /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9]).{6,}/;
      if (newPass.value != "" && !regPassword.test(newPass.value)) {
        ElMessage({
          message:
            "Please input 6+ characters, MUST containing capital and lowercase letters, and number.",
          type: "warning",
        });
        return false;
      }
      post(`/user/update-info`, {
        uname: uname.value,
        email: email.value,
        uid: user.value.uid,
      }).then((res) => {
        if (res.res == 0) {
          layer.load(0, { time: 1500 });
          setTimeout(() => {
            layer.msg("Error, try again...", { icon: 2, time: 1500 });
          }, 1500);
        } else {
          if (newPass.value != "") {
            post(`/user/update-pwd`, {
              pwd: newPass.value,
              uid: user.value.uid,
            }).then((res) => {
              if (res.res == 0) {
                layer.load(0, { time: 1500 });
                setTimeout(() => {
                  layer.msg("Error, try again...", { icon: 2, time: 1500 });
                }, 1500);
              } else {
                layer.load(0, { time: 1500 });
                setTimeout(() => {
                  layer.msg("Info updated!", { icon: 1, time: 1500 });
                  location.reload();
                }, 1500);
              }
            });
          } else {
            layer.load(0, { time: 1500 });
            setTimeout(() => {
              layer.msg("Info updated!", { icon: 1, time: 1500 });
              location.reload();
            }, 1500);
          }
        }
      });
    }

    function checkLogin() {
      if (sessionStorage.getItem("token")) {
        post(`/user/logged`).then((res) => {
          if (res.res == 1) {
            user.value = res.user;
            uname.value = user.value.uname;
            email.value = user.value.email;
            if (user.value.profilePath != 'defaultPath') {
              loading.value = true;
              get("/file/download", { fileName: user.value.profilePath }).then(
                (res) => {
                  avatar.value = res.data;
                  loading.value = false;
                }
              );
            }
            fetchEmailReceiveStatus();
          } else {
            sessionStorage.removeItem("token");
            sessionStorage.removeItem('Authorization');
          }
        });
      }
    }

    function modifyAccount() {
      modifyDialogVisible.value = true;
    }

    function confirmModification() {
      if (newLevel.value < user.value.admin) {
        post("/admin/auth", { auth: newLevel.value, uid: user.value.uid,moderator:user.value.uid }).then(
          (res) => {
            if (res.res === 1) {
              ElMessage({
                type: "success",
                message: "Account level downgraded",
              });
              modifyDialogVisible.value = false;
              checkLogin(); // Refresh user info
            } else {
              ElMessage({
                type: "error",
                message: "Failed to downgrade account level",
              });
            }
          }
        );
      } else {
        post("/message/send-to-level3", {
          senderId: user.value.uid,
          title: "Upgrade Request",
          content: `User ${user.value.uname} requests to upgrade to level ${newLevel.value}.`,
          type: "upgrade_request",
        }).then((res) => {
          console.log(res);
          if (res ==='Message sent successfully.') {
            ElMessage({
              type: "success",
              message: "Upgrade request sent to admin",
            });
            modifyDialogVisible.value = false;
          } else {
            ElMessage({
              type: "error",
              message: "Failed to send upgrade request",
            });
          }
        });
      }
    }
    function fetchEmailReceiveStatus() {
      post('/user/get-email-receive', { uid: user.value.uid }).then((res) => {
        if (res.res===1) {
          receiveEmail.value = res.data === 1;
          console.log(receiveEmail.value)
        } else {
          console.error('Failed to fetch email settings');
        }
      });
    }
    function toggleEmailReceive(newStatus) {
      post('/user/change-email-receive', {
        uid: user.value.uid,
        receiveEmail: newStatus ? 1 : 0
      }).then((res) => {
        if (!res.res===1) {
          receiveEmail.value = !newStatus; // Revert the switch if the API call fails
          ElMessage.error('Failed to update email settings');
        }
      });
    }

   

    checkLogin();

    return {
      user,
      visible,
      changeAvatar,
      newPass,
      check,
      showHide,
      showPass,
      filled,
      isShow,
      uname,
      email,
      save,
      uploadRef,
      upload,
      enUS,
      myUpload,
      avatar,
      loading,
      modifyAccount,
      modifyDialogVisible,
      newLevel,
      level,
      confirmModification,
      receiveEmail,
      toggleEmailReceive
    };
  },
};
</script>

<style scoped>
.my-account {
  display: block;
  padding: 35px 65px 25px 65px;
  /*border: 1px solid;*/
  /*overflow: hidden;*/
}
.ma-wrap {
  display: flex;
}
.ma-face {
  display: flex;
  align-items: center;
  width: 68px;
  font-size: 14px;
  flex-direction: column;
  justify-content: center;
  margin-right: 58px;
}
.ma-face img {
  display: block;
  width: 68px;
  height: 68px;
  border-radius: 50%;
  border: 4px solid #5473ff;
  margin-bottom: 16px;
}
.ma-text {
  width: auto;
}
.ma-item {
  display: flex;
  margin-bottom: 15px;
}
.ma-item label {
  width: 150px;
  font-size: 14px;
  line-height: 32px;
  font-family: "Poppins-SemiBold";
}
.ma-item .form-input {
  width: 280px;
  height: 32px;
  font-size: 14px;
  /*background: #919191;*/
  border-radius: 8px;
}
.icon-edit {
  display: block;
  width: 30px;
  margin-left: 20px;
  padding-top: 3px;
  /*border: 1px solid red;*/
  line-height: 32px;
}
.icon-edit:hover {
  cursor: pointer;
}
/*.icon-edit a{*/
/*  display: block;*/
/*  width: 100%;*/
/*  height: 100%;*/
/*}*/
.mya-item {
  display: block;
  margin-bottom: 45px;
}
.mya-item h2 {
  display: block;
  font-size: 18px;
  font-family: "Poppins-SemiBold";
  color: #111880;
  margin-bottom: 20px;
}
.mya-wrap {
  display: flex;
  justify-content: space-between;
}
.myai-text {
  font-size: 14px;
  line-height: 1.6;
}
a.myai-btna {
  display: block;
  width: 102px;
  height: 34px;
  line-height: 34px;
  text-align: center;
  margin-left: 35px;
  font-size: 14px;
  color: #ffffff;
  border-radius: 17px;
  font-family: "Poppins-SemiBold";
  background-color: #c4c4c4;
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.2);
}
a.myai-btna:hover {
  background-color: #b4b4b4;
  box-shadow: 0px 2px 20px 0px rgba(59, 93, 245, 0.5);
}
a.mya-btn {
  display: block;
  margin: 0 auto;
  width: 120px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 16px;
  color: #ffffff;
  border-radius: 20px;
  font-family: "Poppins-SemiBold";
  background-color: #5473ff;
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.2);
}
a.mya-btn:hover {
  background-color: #2473ff;
  box-shadow: 0px 2px 20px 0px rgba(59, 93, 245, 0.5);
}
.btn-box {
  display: block;
  position: relative;
}
.submit-tips {
  position: absolute;
  display: none;
  left: 50%;
  bottom: 65px;
  height: 105px;
  border-radius: 20px;
  text-align: center;
  padding: 20px 25px;
  background-color: #ffffff;
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.2);
  transform: translate(-50%, 0);
}
.submit-tips.show {
  display: block;
}
.submit-tips h2 {
  font-size: 20px;
  color: #11187d;
  padding-top: 5px;
  padding-bottom: 12px;
}
.submit-tips span {
  font-size: 16px;
  color: #8a8a8e;
  line-height: 1.6;
}

.upgrade-downgrade-btns {
  display: flex;
  justify-content: center;
  gap: 50px;
  margin-top: 50px;
}
</style>
